<template>
    <tr
            :class="[{'sm': isModal}, rowClass(subscription.status.value)]"
    >
        <td class="number">{{index + 1}}</td>
        <td class="date">
            <SubscriptionHistoryDatepicker
                    v-if="isModal && (typeof subscription.id === 'string' || isPossibleToEditSubscription)"
                    :date="subscription.startDate"
                    :disabled-dates="disabledDates"
                    @onDateSelected="typeof subscription.id === 'string' ? onDateSelectedNew(...arguments, 'start') : onDateSelected(...arguments, 'start')"
            />
            <p v-else>{{date(subscription.startDate)}}</p>
        </td>
        <td class="date">
            <SubscriptionHistoryDatepicker
                    v-if="isModal && (typeof subscription.id === 'string' || isPossibleToEditSubscription)"
                    :date="subscription.endDate"
                    :min-date="subscription.startDate"
                    :disabled-dates="disabledDates"
                    @onDateSelected="typeof subscription.id === 'string' ? onDateSelectedNew(...arguments, 'end') : onDateSelected(...arguments, 'end')"
            />
            <p v-else>{{date(subscription.endDate)}}</p>
        </td>
        <td>
            <button
                    v-if="typeof subscription.id === 'number'"
                    @click="downloadPaymentInvoice"
                    type="button"
                    class="download-link"
            >
                {{subscription.paymentInvoiceName}}
            </button>
        </td>
        <td>
            <button
                    v-if="typeof subscription.id === 'number'"
                    @click="downloadContractPdf"
                    type="button"
                    class="download-link"
            >
                {{subscription.contractName}}
            </button>
        </td>
        <td>
            <button
                    v-if="isPossibleToDownloadCompleteWorkReport"
                    @click="downloadCompletedWorkReport"
                    type="button"
                    class="download-link"
            >
                {{subscription.completedWorkReportName}}
            </button>
        </td>
        <td class="status">
            <div
                    class="dropdown top md"
                    v-if="isModal && (typeof subscription.id === 'string' || isPossibleToEditSubscription)"
                    v-click-outside="closeDropdown">
                <button
                        class="dropdown-btn"
                        type="button"
                        @click="isDropdownOpen = !isDropdownOpen"
                >
                    <span>{{subscription.status.value}}</span>
                    <ico name="icoArrowDropDown" color="#263239"/>
                </button>
                <div v-if="isDropdownOpen"
                     ref="dropdown"
                     class="dropdown-popup">
                    <button v-for="(status) in subscriptionStatusesTypes"
                            :key="status.id"
                            type="button"
                            :class="{active: subscription.status.id === status.id}"
                            @click.stop="typeof subscription.id === 'string' ? changeStatusNew(status) : changeStatus(status)"
                    >
                        {{status.value}}
                    </button>
                </div>
            </div>
            <p v-else>{{subscription.status.value}}</p>
        </td>
        <td v-if="isModal" class="actions">
            <button
                    type="button"
                    title="Сохранить изменения"
                    @click="typeof subscription.id === 'string' ? saveNewSubscription() : updateSubscription()"
                    :disabled="subscription.isSubscriptionSaved || isRequesting"
            >
                <span v-if="subscription.isSubscriptionSaved" key="saved">
                    <ico name="icoDoneAll" color="#79919C"/>
                </span>
                <span v-if="!subscription.isSubscriptionSaved" key="unsaved">
                    <ico name="icoDone" color="#79919C"/>
                </span>
            </button>
            <button
                    type="button"
                    title="Удалить подписку"
                    @click="confirmRemovingSubscription"
                    :disabled="isRequesting"
            >
                <ico name="icoDelete" color="#79919C"/>
            </button>
        </td>
    </tr>
</template>
<script>
    import addDays from 'date-fns/addDays'
    import SubscriptionHistoryDatepicker from "@/components/profilePage/SubscriptionHistoryDatepicker"
    import {mapGetters} from "vuex"
    import {eventBus} from "@/main"
    import api from "@/application"
    import {fileDownload} from "@/utils/fileDownload"
    import {find} from "lodash"
    import format from "date-fns/format"
    import {subscriptionSize} from "@/utils/constants/subscription"

    export default {
        name: "SubscriptionHistoryTableRow",
        data() {
            return {
                isDropdownOpen: false,
            }
        },
        props: ['subscription', 'index', 'isModal', 'disabledDates'],
        components: {
            SubscriptionHistoryDatepicker,
        },
        computed: {
            ...mapGetters('enums', ['subscriptionStatusesTypes']),
            ...mapGetters('subscriptions', ['subscriptions', 'generalSubscriptions', 'isRequesting']),
            isPossibleToDownloadCompleteWorkReport() {

                return find(this.generalSubscriptions, ['id', this.subscription.id])?.status?.id === 3
            },
            isPossibleToEditSubscription() {

                return find(this.generalSubscriptions, ['id', this.subscription.id])?.status?.id === 1
            },
        },
        methods: {
            rowClass(status) {
                return status === 'Завершена' ? 'danger' : status === 'Оплачена' ? 'success' : 'warning'
            },
            date(date) {

                return format(date, 'dd.MM.yyyy')
            },
            closeDropdown() {
                this.isDropdownOpen = false
            },
            onDateSelectedNew(date, type) {
                if (type === 'start') {
                    eventBus.$emit('changeNewSubscription', this.subscription.id, {
                        ...this.subscription,
                        startDate: date,
                        endDate: addDays(date, subscriptionSize),
                    })
                    return
                }
                eventBus.$emit('changeNewSubscription', this.subscription.id, {
                    ...this.subscription,
                    endDate: date,
                })
            },
            onDateSelected(date, type) {
                if (type === 'start') {
                    eventBus.$emit('changeSubscription', this.subscription.id, {
                        ...this.subscription,
                        startDate: date,
                        endDate: addDays(date, subscriptionSize),
                    })
                    return
                }
                eventBus.$emit('changeSubscription', this.subscription.id, {
                    ...this.subscription,
                    endDate: date,
                })
            },
            changeStatusNew(status) {
                eventBus.$emit('changeNewSubscription', this.subscription.id, {
                    ...this.subscription,
                    status
                })
                this.closeDropdown()
            },
            changeStatus(status) {
                eventBus.$emit('changeSubscription', this.subscription.id, {
                    ...this.subscription,
                    status
                })
                this.closeDropdown()
            },
            updateSubscription() {
                eventBus.$emit('updateSubscription', this.subscription.id, this.subscription)
            },
            saveNewSubscription() {
                eventBus.$emit('saveSubscription', this.subscription)
            },
            confirmRemovingSubscription() {
                const date = this.date(this.subscription.startDate)
                if (typeof this.subscription.id === 'string') {
                    eventBus.$emit('removeNewSubscription', this.subscription.id)
                    return
                }
                eventBus.$emit('showConfirmation', this.subscription.id, date)
            },
            async downloadPaymentInvoice() {

                try {
                    const response = await api.pdf.getPaymentInvoice(this.subscription.id)
                    fileDownload(response)
                } catch (e) {
                    this.$toast.error(e.message)
                }

            },
            async downloadContractPdf() {

                try {
                    const response = await api.pdf.getContract(this.subscription.id)
                    fileDownload(response)
                } catch (e) {
                    this.$toast.error(e.message)
                }

            },
            async downloadCompletedWorkReport() {

                try {
                    const response = await api.pdf.getCompletedWorkReport(this.subscription.id)
                    fileDownload(response)
                } catch (e) {
                    this.$toast.error(e.message)
                }

            }

        },
    }
</script>
<style lang="less" scoped>
    @import '../../assets/less/variables.less';

    td {
        vertical-align: top;
        border: none;
        border-bottom: 2px solid @bg-color-light;
        padding: 8px 16px;
        letter-spacing: 0.3px;
    }

    tr.warning td {
        background-color: fade(@warning-color, 10%);
    }

    tr.success td {
        background-color: fade(@success-color, 10%);
    }

    tr.danger td {
        background-color: fade(@error-color, 10%);
    }

    td {
        &.number {
            padding: 8px 0 8px 16px;
            min-width: 60px;
        }

        &.date {
            min-width: 125px;
        }

        &.actions {
            padding: 8px 16px 8px 0;
            width: 72px;

            button {
                margin-right: 8px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    tr.sm {
        td {
            padding: 12px 8px;
            vertical-align: middle;

            &.number {
                padding: 12px 4px;
                min-width: 0;
                width: 42px;
            }

            &.actions {
                padding: 8px 4px;
            }

            &.status {
                min-width: 190px;
            }
        }
    }
    .download-link {
        text-align: left;
        text-decoration: underline;
        color: @text-color-middle;
    }
</style>