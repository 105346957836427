<template>
    <v-date-picker
            :key="datePickerKey"
            :locale="'ru-Ru'"
            :masks="{input: 'DD.MM.YYYY'}"
            :value="subscriptionDate"
            :title-position="'left'"
            :color="'gray'"
            :min-date="minDate"
            :disabled-dates="disabledDates"
            @input="$emit('onDateSelected', $event)"
    >
        <template v-slot="{ inputValue, togglePopover }">
            <div class="datepicker-container">
                <input
                        :value="inputValue"
                        type="text"
                        readonly
                >
                <button
                        type="button"
                        @click="togglePopover"
                >
                    <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                        <path fill="#79919C"
                              d="M7,12H9V14H7V12M21,6V20A2,2 0 0,1 19,22H5C3.89,22 3,21.1 3,20V6A2,2 0 0,1 5,4H6V2H8V4H16V2H18V4H19A2,2 0 0,1 21,6M5,8H19V6H5V8M19,20V10H5V20H19M15,14V12H17V14H15M11,14V12H13V14H11M7,16H9V18H7V16M15,18V16H17V18H15M11,18V16H13V18H11Z"/>
                    </svg>
                </button>
            </div>
        </template>
    </v-date-picker>
</template>
<script>
    import {uuid} from "vue-uuid"
    export default {
        name: "SubscriptionHistoryDatepicker",
        data() {
            return {
                subscriptionDate: new Date(),
                datePickerKey: uuid.v4()
            }
        },
        props: ['date', 'minDate', 'disabledDates'],
        watch: {
            date() {
                this.subscriptionDate = this.date
            },
            disabledDates() {
                this.datePickerKey = uuid.v4()
            },
        },
        created() {
            this.subscriptionDate = this.date
        }
    }
</script>
<style lang="less" scoped>
    @import '../../assets/less/variables.less';

    .datepicker-container {
        display: flex;
        justify-content: space-between;

        input {
            flex-shrink: 1;
            flex-grow: 1;
            display: block;
            border: none;
            border-radius: 0;
            padding: 0;
            width: 72px;
            font-size: inherit;
            font-family: inherit;
            text-overflow: ellipsis;
            color: @text-color;
            background-color: transparent;

            &:focus {
                outline: none;
                border: none;
            }
        }

        button {
            flex-shrink: 0;
            flex-grow: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: @br;
            width: 32px;
            height: 32px;
            background-color: transparent;

            svg {
                display: block;
            }
        }
    }
</style>