<template>
    <div v-if="!isManager">
        <Multiselect
                :class="{sm: isSmall}"
                :value="value"
                :options="options"
                :searchable="searchable"
                :show-labels="showLabels"
                :multiple="multiple"
                :placeholder="placeholder"
                :disabled="disabled"
                @input="onInput"
        />
    </div>
    <div v-else>
        <multiselect
            :class="{sm: isSmall}"
            v-model="selectedOption"
            track-by="email" 
            label="organization" 
            :options="options" 
            :searchable="searchable"
            :show-labels="showLabels"
            :multiple="multiple"
            :placeholder="placeholder"
            :disabled="disabled"
            :allow-empty="false"
        >
            <template
                slot-scope="{ option }">
                {{ option.organization }}
            </template>
        </multiselect>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'

    export default {
        name: "EnumSelect",
        data () {
            return {
                savedValue: null,
            }
        },
        components: {
            Multiselect,
        },
        props: {
            isSmall: {
                type: Boolean,
            },
            value: {
                required: true,
            },
            options: {
                type: Array,
                required: true,
            },
            multiple: {
                type: Boolean,
                required: false,
                default: false,
            },
            placeholder: {
                type: String,
                required: false,
                default: null,
            },
            searchable: {
                type: Boolean,
                required: false,
                default: false,
            },
            showLabels: {
                type: Boolean,
                required: false,
                default: false,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            isManager: {
                type: Boolean,
                required: false,
                default: false,
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(value) {
                    this.savedValue = value;
                }
            }
        },
        computed: {
            selectedOption: {
                get() {
                    return this.options.find(option => option.email === this.savedValue);
                },
                set(option) {
                    this.savedValue = option.email;
                    this.$emit('onChange', option.email);
                }
            }
        },
        methods: {
            onInput(value) {
                this.$emit('onChange', value)
            },      
        }
    }
</script>

<style lang="less">
    @import '../../assets/less/variables.less';
    @import '../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css';

    .multiselect {
        min-height: 44px;
        // min-width: 172px;
    }
    .multiselect__select {
        height: 42px;
        &::before {
            border-color: @bg-color transparent transparent;
            color: @bg-color;
        }
    }
    .multiselect__tags {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: @br;
        border: 2px solid @text-color-lighter;
        margin: 1px 1px 1px 0;
        padding: 0 40px 0 16px;
        min-height: 44px;
    }
    .multiselect__tags-wrap {
        overflow-x: hidden;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        min-height: 40px;
        &::-webkit-scrollbar {
            border-radius: @br;
            width: 8px;
            background-color: #ffffff;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: @br;
            width: 8px;
            background-color: fade(#000000, 24%);
            &:hover {
                background-color: fade(#000000, 30%);
            }
        }
    }
    .multiselect__tag {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        margin: 1px 1px 1px 0;
        border-radius: 12px;
        padding: 0 8px 0 8px;
        height: 24px;
        font-size: 12px;
        color: @bg-color;
        background-color: @bg-color-middle;
        span {
            overflow: hidden;
            max-width: 100%;
        }
    }
    .multiselect__tag-icon {
        top: 4px;
        right: 4px;
        bottom: 4px;
        margin-left: 4px;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        font-weight: 400;
        line-height: 17px;
        background-color: @text-color-light;
        &::after {
            color: #ffffff;
        }
        &:hover, &:focus {
            background-color: @bg-color;
        }
    }
    .multiselect__placeholder {
        margin-bottom: 0;
        line-height: 1;
        color: @text-color-light;
    }
    .multiselect__content-wrapper {
        margin-top: -2px;
        border-radius: 3px;
        border: 2px solid @text-color-lighter;
        background-color: #ffffff;
        &::-webkit-scrollbar {
            border-radius: @br;
            width: 8px;
            height: 8px;
            background-color: #ffffff;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: @br;
            width: 8px;
            height: 8px;
            background-color: fade(#000000, 24%);
            &:hover {
                background-color: fade(#000000, 30%);
            }
        }
    }
    .multiselect__option {
        padding: 8px 16px;
        min-height: 32px;
        font-size: 15px;
        letter-spacing: 0.3px;
        color: @text-color;
        &.multiselect__option--highlight {
            color: @text-color;
            background-color: @bg-color-light;
        }
    }
    .multiselect__option--selected {
        font-weight: 400;
        color: @text-color;
        background-color: @bg-color-light;
    }
    .multiselect__option--selected.multiselect__option--highlight {
        color: @text-color;
        background-color: @bg-color-light;
    }
    .multiselect__single {
        margin-bottom: 0;
        font-size: 14px;
    }
    .multiselect.multiselect--disabled {
        .multiselect__select {
            background: transparent;
        }
    }
    .multiselect.sm {
        padding-top: 2px;
        min-height: 24px;
        height: 24px;
        width: 60px;
        .multiselect__select {
            padding: 0;
            width: 16px;
            height: 24px;
            &::before {
                border-color: @text-color-light transparent transparent;
                color: @text-color-light;
            }
        }
        .multiselect__tags {
            border: none;
            border-radius: 0;
            min-height: 24px;
            height: 24px;
            margin: 1px 1px 1px 0;
            padding: 0 24px 0 8px;
        }
        .multiselect__single {
            vertical-align: middle;
            padding-left: 0;
            min-height: 0;
            font-size: 14px;
            line-height: 1.2;
        }
        .multiselect__content-wrapper {
            border-radius: @br;
            border: 1px solid @text-color-lighter;
        }
        .multiselect__option {
            padding: 8px;
            font-size: 14px;
        }
    }
    span.multiselect__single {
        text-wrap: nowrap;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
