<template>
    <div>
        <div class="users-search-panel">
            <input
                    :value="filters.emailFilter.value"
                    @change="onChangeFilter('emailFilter', $event)"
                    type="text"
                    placeholder="Искать по email"
            >
            <input
                    type="text"
                    :value="filters.organizationFilter.value"
                    @change="onChangeFilter('organizationFilter', $event)"
                    placeholder="Искать по организации"
            >
            <input
                    type="text"
                    :value="filters.unpFilter.value"
                    @change="onChangeFilter('unpFilter', $event)"
                    placeholder="Искать по УНП"
            >
            <EnumSelect
                v-if="authorities.includes('ROLE_ADMIN')"
                :value="takedManagerEmail"
                :options="[{email:'', organization: 'Не выбрано'}, ...managers]"
                :show-labels="false"
                :is-manager="true"
                @onChange="onChangeSortFilter(...arguments)"
            />
        </div>
        <div class="users-table-wrapper">
            <table>
                <thead>
                <tr>
                    <th class="state">
                        <div class="state-indicator pointer " 
                            :class="{'default': sortStepper === 1, 'online': sortStepper === 2, 'offline': sortStepper === 3}"
                            @click="sortByOnline()">
                        </div>
                    </th>
                    <th class="number">&nbsp;</th>
                    <th class="date">Дата создания</th>
                    <th class="email">Email</th>
                    <th class="organization">Организация</th>
                    <th class="unp">УНП</th>
                    <th class="date">Дата окончания подписки</th>
                    <th class="days-left">Осталось дней</th>
                    <th class="filters">Фильтры/слова</th>
                    <th class="actions">Действия</th>
                    <th v-if="authorities.includes('ROLE_ADMIN') || authorities.includes('ROLE_MANAGER')" class="manager">Менеджер</th>
                    <th v-if="authorities.includes('ROLE_ADMIN')" class="role">Роль</th>
                    <th v-if="authorities.includes('ROLE_ADMIN')" class="status">Статус</th>
                </tr>
                </thead>
                <tbody>
                <tr
                        class="users-table-wrapper-row"
                        v-for="(user, index) in users" :key="user.profileId"
                >
                    <td class="state">
                        <div
                                class="state-indicator"
                                :class="user.online ? 'online' : 'offline'"
                        />
                    </td>
                    <td class="number">{{Number(page.number * page.size + index + 1)}}</td>
                    <td class="date">{{date(user.dateAdded)}}</td>
                    <td class="email">{{user.email}}</td>
                    <td class="organization">{{user.organization}}</td>
                    <td class="unp">{{user.unp}}</td>
                    <td class="date">{{user.daysLeft > 20000 ? '&infin;' : date(user.subscriptionEndDate)}}</td>
                    <td class="days-left">{{user.daysLeft > 20000 ? '&infin;' : user.daysLeft}}</td>
                    <td class="filters">{{user.filterInfo}}</td>
                    <td class="actions">
                        <div class="user-action-btns">
                            <button
                                    class="user-action-btn"
                                    type="button"
                                    title="История подписок пользователя"
                                    @click="openSubscriptionHistoryModal(user)"
                            >
                                <ico name="icoAssignment" color="#79919C"></ico>
                            </button>
                            <button
                                    class="user-action-btn"
                                    type="button"
                                    @click="loginViaImpersonalization(user)"
                                    title="Войти в систему, используя данные пользователя"
                            >
                                <ico name="icoPerson" color="#79919C"/>
                            </button>
                            <DeleteBtn
                                    v-if="authorities.includes('ROLE_ADMIN')"
                                    :user="user"
                                    :filters="filters"
                            />
                        </div>
                    </td>
                    <td v-if="authorities.includes('ROLE_ADMIN') || authorities.includes('ROLE_MANAGER')" class="manager">
                        <EnumSelect
                                :value="user.managerEmail"
                                :disabled="disableEditMainAdmin(user.role) || disableEditUser(user.role) || authorities.includes('ROLE_MANAGER')"
                                :options="[{email:'', organization: ''}, ...managers]"
                                :show-labels="false"
                                :is-manager="true"
                                @onChange="callSetManagerToUser(...arguments, user)"
                        />
                    </td>
                    <td v-if="authorities.includes('ROLE_ADMIN')" class="role">
                        <EnumSelect
                                :value="user.role"
                                :disabled="disableEditMainAdmin(user.role)"
                                :options="userRoles"
                                :show-labels="false"
                                @onChange="onInputRole(...arguments, user)"
                        />
                    </td>
                    <td v-if="authorities.includes('ROLE_ADMIN')" class="status">
                        <EnumSelect
                                :value="status(user.enabled)"
                                :options="userStatuses"
                                :show-labels="false"
                                :disabled="disableEditMainAdmin(user.role)"
                                @onChange="onInputStatus(...arguments, user)"
                        />
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="users-table-footer">
            <Pagination
                    :page="page"
                    :links="links"
                    @onChangePagination="onChangePagination"
                    @onChangePage="onChangePage"
            ></Pagination>
        </div>
    </div>
</template>
<script>
    import {mapActions, mapGetters} from 'vuex'
    import Pagination from "@/components/common/Pagination"
    import DeleteBtn from "@/components/usersPage/DeleteBtn"
    import {purchasesPath} from "@/router/purchases"
    import {SubscriptionHistoryModalProperties} from "@/utils/modalsProperties"
    import SubscriptionHistoryModal from "@/components/profilePage/SubscriptionHistoryModal"
    import EnumSelect from "@/components/common/EnumSelect"
    import {serviceUnavailable} from "@/utils/constants/errorMessages"
import Vue from 'vue'

    export default {
        name: "Users",
        components: {
            Pagination,
            DeleteBtn,
            EnumSelect,
        },
        data() {
            return {
                filters: {
                    emailFilter: {
                        value: '',
                        operation: ':',
                        path: 'email',
                    },
                    organizationFilter: {
                        value: '',
                        operation: ':',
                        path: 'organization.shortNameOrganization',
                    },
                    unpFilter: {
                        value: '',
                        operation: ':',
                        path: 'organization.unp',
                    },
                },
                userStatuses: [
                    'Активен',
                    'Деактивирован',
                ],
                userRoles: [
                    'Пользователь',
                    'Администратор',
                    'Менеджер'
                ],
                sortStepper: 1,
                takedManagerEmail: localStorage.getItem('sortFilter') !== '' && typeof localStorage.getItem('sortFilter') === 'string' ? localStorage.getItem('sortFilter') : '',
            }
        },
        computed: {
            ...mapGetters('users', ['users', 'page', 'links', 'managers']),
            ...mapGetters('purchasesTableSettings', ['pagination']),
            ...mapGetters('auth', ['email', 'authorities']),
            ...mapGetters('profile', ['profileId']),
            ...mapGetters('impersonalization', ['mindControlledUser']),
            managersKey() {
                return this.managers.length;
            }
        },
        methods: {
            ...mapActions('users', [
                'getUsers', 
                'updateUser', 
                'addUserRole', 
                'removeUserRole', 
                'changeUserRole', 
                'sortUsersByOnline', 
                'getManagers', 
                'getLinkedToManagerUsers',
                'setManagerToUser',
                'deleteManagerFromUser'
            ]),
            ...mapActions('impersonalization', ['turnOnImpersonalizationMode']),
            ...mapActions('notifications', ['getDisplayedNotifications', 'getAllNotifications', 'getNewNotifications']),
            managersList() {
                return this.managers.map(manager => manager.managersList)
            },
            async loginViaImpersonalization({email, profileId, enabled}) {
                if (!enabled) {
                    this.$toast.error('Пользователь деактивирован')
                    return
                }

                if (email === this.email) {
                    this.$toast.error('Сильно умный?')
                    return
                }

                try {
                    await this.getAllNotifications(profileId)
                    await this.getNewNotifications(profileId)
                    await this.getPopUpNotificationsImpersonalizations(profileId)
                    await this.turnOnImpersonalizationMode(email)
                    
                    this.$router.push(purchasesPath)
                } catch (e) {
                    this.$toast.error(serviceUnavailable + "(7)")
                }

            },
            onChangeFilter(key, event) {
                this.filters[key].value = event.target.value
                if (this.authorities.includes('ROLE_ADMIN')) {
                    this.getUsers({
                        size: this.pagination.value,
                        filters: this.filters,
                    })
                } else {
                    this.getLinkedToManagerUsers({
                            size: this.pagination.value,
                            email: this.email,
                            filters: this.filters,
                        })
                }
            },
            onChangePagination() {
                const params = {
                    size: this.pagination.value,
                    filters: this.filters,
                };
                if (this.authorities.includes('ROLE_ADMIN')) {
                    this.takedManagerEmail === '' ? this.getUsers(params) : this.getLinkedToManagerUsers({...params, email: this.takedManagerEmail});
                } else {
                    this.getLinkedToManagerUsers({
                        ...params,
                        email: this.email,
                    });
                }
            },
            onChangePage(page) {
                const params = {
                    size: this.pagination.value,
                    filters: this.filters,
                    page
                };

                if (this.sortStepper === 1) {
                    if (this.authorities.includes('ROLE_ADMIN')) {
                        this.takedManagerEmail === '' ? this.getUsers(params) : this.getLinkedToManagerUsers({...params, email: this.takedManagerEmail});
                    } else {
                        this.getLinkedToManagerUsers({
                            ...params,
                            email: this.email,
                        });
                    }
                } else {
                    this.sortUsersByOnline({
                        page,
                        size: this.pagination.value,
                        isOnline: this.sortStepper === 2 ? true : false,
                        role: this.authorities.includes('ROLE_ADMIN') ? (this.takedManagerEmail === '' ? 'users' : `managers/${this.takedManagerEmail}`) : `managers/${this.email}`
                    });
                }
            },
            async onChangeSortFilter(value) {
                this.takedManagerEmail = value;
                if(value !== null){
                    localStorage.setItem('sortFilter', value)
                }
                try {
                    await this.getManagers();
                    if (!value.length) {
                        await this.getUsers({
                            size: this.pagination.value,
                            filters: this.filters,
                        });
                    } else {
                        await this.getLinkedToManagerUsers({
                            size: this.pagination.value,
                            email: value,
                            filters: this.filters,
                        });
                    }           
                } catch (error) {
                    this.$toast.error(error.message)
                }
            },
            date(date) {
                const formatter = new Intl.DateTimeFormat('ru-RU', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                })
                return formatter.format(new Date(date))
            },
            status(status) {
                return status ? 'Активен' : 'Деактивирован'
            },
            disableEditMainAdmin(role) {
                return role === 'Главный Администратор'
            },
            disableEditUser(role) {
                if (role === 'Администратор') {
                    return true;
                } else {
                    return false;
                }
            },
            openSubscriptionHistoryModal({role, enabled, profileId}) {

                if (!enabled) {
                    this.$toast.error('Запрещено добавлять подписку деактивированому Пользователю')
                    return
                }

                if (role == 'Администратор') {
                    this.$toast.error('Запрещено добавлять подписку Администратору')
                    return
                }

                this.$modal.show(
                    SubscriptionHistoryModal,
                    {
                        profileId
                    },
                    SubscriptionHistoryModalProperties
                )
            },
            async onInputStatus(status, user) {
                if (status) {
                    try {
                        await this.updateUser({
                            enabled: !user.enabled,
                            email: user.email,
                        })
                        this.getUsers({
                            size: this.pagination.value,
                            page: this.page.number,
                            filters: this.filters,
                        })
                        this.$toast.success('Статус изменен')
                    } catch (e) {
                        this.$toast.error(e.message)
                    }

                }
            },
            async sortByOnline() {
                let params;
                switch (this.sortStepper) {
                    case 1:
                        await this.sortUsersByOnline({
                            size: this.pagination.value,
                            isOnline: true,
                            role: this.authorities.includes('ROLE_ADMIN') ? (this.takedManagerEmail === '' ? 'users' : `managers/${this.takedManagerEmail}`) : `managers/${this.email}`
                        });
                        this.page.number = 0;
                        this.sortStepper = 2;
                        break;
                    case 2:
                        await this.sortUsersByOnline({
                            size: this.pagination.value,
                            isOnline: false,
                            role: this.authorities.includes('ROLE_ADMIN') ? (this.takedManagerEmail === '' ? 'users' : `managers/${this.takedManagerEmail}`) : `managers/${this.email}`
                        });
                        this.page.number = 0;
                        this.sortStepper = 3;
                        break;
                    case 3:
                        params = {
                            size: this.pagination.value,
                            filters: this.page.number,
                            page: 0
                        };
                        if (this.authorities.includes('ROLE_ADMIN')) {
                            this.takedManagerEmail === '' ? this.getUsers(params) : this.getLinkedToManagerUsers({...params, email: this.takedManagerEmail});
                        } else {
                            await this.getLinkedToManagerUsers({
                                ...params,
                                email: this.email
                            });
                        }
                        this.sortStepper = 1;
                        break;
                }
            },
            async onInputRole(role, user) {
                try {
                    if (role === 'Пользователь') {
                        switch (user.role) {
                            case 'Пользователь':
                            case 'Администратор':
                            await this.removeUserRole({roleId: 2, email: user.email});
                                break;
                            case 'Менеджер':
                            await this.removeUserRole({roleId: 3, email: user.email});
                                break;
                        }
                    } else if (role === 'Администратор') {
                        switch (user.role) {
                            case 'Пользователь':
                            this.addUserRole({roleId: 2, email: user.email})
                                break;
                            case 'Менеджер':
                            await this.changeUserRole({currentId: 3, roleId: 2, email: user.email});
                                break;
                        }
                    } else if (role === 'Менеджер') {
                        switch (user.role) {
                            case 'Пользователь':
                            await this.addUserRole({roleId: 3, email: user.email});
                                break;
                            case 'Администратор':
                            await this.changeUserRole({currentId: 2, roleId: 3, email: user.email});
                                break;
                        }
                    }
                    await this.getManagers();
                    this.getUsers({
                        size: this.pagination.value,
                        page: this.page.number,
                        filters: this.filters,
                    });
                    this.$toast.success('Статус изменен')
                } catch (e) {
                    this.$toast.error(e.message)
                }

            },
            async callSetManagerToUser(managerEmail, user) {
                const userEmail = user.email;
                try {
                    if (managerEmail === '') {
                        await this.deleteManagerFromUser({
                            manager : user.managerEmail,
                            email: user.email
                        });
                        await this.getManagers();
                        await this.getUsers({
                                size: this.pagination.value,
                                page: this.page.number,
                                filters: this.filters,
                            });
                    } else {
                        await this.setManagerToUser({managerEmail, userEmail});
                        this.$toast.success('Менеджер прикреплен к пользователю.');
                    }
                } catch (error) {
                    this.$toast.error(error.message)
                }
            },
            getPopUpNotificationsImpersonalizations(id){
                this.getDisplayedNotifications(id).then(data => {
                    if(typeof data == 'string'){
                        this.getPopUpNotificationsImpersonalizations(id)
                    }else{
                        for(let item of data){
                            for(let i = 0; i < 3; i++){
                                Vue.notify({
                                    group: 'templateNotification',
                                    title: item.header,
                                    text: item.content,
                                    duration: -1,
                                    max: 3,
                                    ignoreDuplicates: true,
                                    data: {
                                        active: item.buttonIncluded,
                                        priority: item.priority,
                                        notificationId: item.id,
                                    }
                                })
                            }   
                        }
                        this.getNewNotifications(this.profileId)
                    } 
                })
            } 
        },
        async created() {
            try {
                await this.getManagers();
                if (this.authorities.includes('ROLE_ADMIN')) {
                    if(typeof localStorage.getItem('sortFilter') === 'string' && localStorage.getItem('sortFilter') !== ''){
                        this.onChangeSortFilter(localStorage.getItem('sortFilter'))
                    }else{
                        await this.getUsers({
                            size: this.pagination.value,
                        });
                    }  
                } else {
                    await this.getLinkedToManagerUsers({
                        size: this.pagination.value,
                        email: this.email
                    });
                }
            } catch (e) {
                this.$toast.error(e.message)
            }
        },
    }
</script>
<style lang="less" scoped>
    @import '../assets/less/variables.less';

    .users-search-panel {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 24px;

        input {
            margin-right: 32px;
            border: none;
            border-bottom: 2px solid @text-color-lighter;
            width: 25%;
            height: 40px;

            &:last-child {
                margin-right: 0;
            }

            .placeholder({
                color: @text-color-light;
                transition: @trans;
            });

            &:focus {
                outline: none;
                border: none;
                border-bottom: 2px solid @text-color-light;
                .placeholder({
                    opacity: 0.3;
                })
            }
        }
    }

    .users-table-wrapper {
        overflow: auto;
        height: calc(100vh - 16px - 64px - 48px);

        &-row:hover {
            background-color: @bg-color-light;
        }

        &::-webkit-scrollbar {
            border-radius: @br;
            width: 8px;
            height: 8px;
            background-color: #ffffff;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: @br;
            width: 8px;
            height: 8px;
            background-color: fade(#000000, 24%);

            &:hover {
                background-color: fade(#000000, 30%);
            }
        }
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        height: auto;
        text-align: left;

        th {
            position: sticky;
            top: 0;
            z-index: 1;
            border: none;
            border-bottom: 2px solid @bg-color-light;
            padding: 12px 24px;
            font-weight: 500;
            color: @text-color-light;
            background-color: #ffffff;
        }

        td {
            vertical-align: middle;
            border: none;
            border-bottom: 2px solid @bg-color-light;
            padding: 8px 24px;
            letter-spacing: 0.3px;
        }

        th, td {

            vertical-align: middle;

            &.state {
                padding: 15px 0 12px;
                min-width: 10px;
            }

            &.number {
                padding: 12px 0 12px 16px;
                min-width: 60px;
            }

            &.date {
                min-width: 125px;
            }

            &.email {
                min-width: 250px;
            }

            &.organization {
                min-width: 200px;
            }

            &.unp {
                min-width: 125px;
            }

            &.status {
                min-width: 200px;
            }

            &.role {
                min-width: 160px;
            }

            &.days-left {
                min-width: 125px;
            }

            &.filters {
                min-width: 125px;
            }

            &.actions {
                min-width: 140px;
            }

        }
    }

    .users-table-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        background-color: #ffffff;
        box-shadow: 0 -2px 4px 0 rgba(38, 50, 57, 0.24);
    }

    .state-indicator {
        border-radius: 50%;
        width: 10px;
        height: 10px;

        &.online {
            background-color: @success-color;
        }

        &.offline {
            background-color: @error-color;
        }

        &.default {
            border: 1px solid #CFD8DD;
            background-color: #ffffff;
        }
        &.pointer {
            cursor: pointer;
        }
    }

    .user-action-btns {
        display: flex;
        justify-content: flex-start;
    }

    .user-action-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 4px;
        width: 32px;
        height: 32px;

        &:last-child {
            margin-right: 0;
        }
    }
</style>