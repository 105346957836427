<template>
    <button
            class="user-action-btn"
            type="button"
            title="Удалить пользователя"
            @click="openConfirmModal"
            :disabled="user.role === 'Главный Администратор'"
    >
        <ico name="icoDelete" color="#79919C"/>
    </button>
</template>
<script>
    import ConfirmModal from "@/components/common/ConfirmModal"
    import {ConfirmModalProperties} from "@/utils/modalsProperties"
    import {mapActions, mapGetters} from "vuex"
    import {serviceUnavailable} from "@/utils/constants/errorMessages"

    export default {
        name: "DeleteBtn",
        props: ['user', 'filters'],
        computed: {
            ...mapGetters('users', ['page']),
            ...mapGetters('purchasesTableSettings', ['pagination']),
        },
        methods: {
            ...mapActions('users', ['deleteUser', 'getUsers']),
            async remove() {
                try {
                    await this.deleteUser(this.user.email)
                    this.getUsers({
                        size: this.pagination.value,
                        page: this.page.number,
                        filters: this.fitlers,
                    })
                    this.$toast.success('Пользователь успешно удален')
                } catch (e) {
                    this.$toast.error(serviceUnavailable + "(5)")
                }

            },
            openConfirmModal() {
                this.$modal.show(
                    ConfirmModal,
                    {
                        text: `Вы действительно хотите удалить пользователя <strong>${this.user.email}</strong>?`,
                        callback: this.remove
                    },
                    ConfirmModalProperties
                )
            },
        }
    }
</script>
<style scoped>
</style>