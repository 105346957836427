<template>
    <div
        class="subscription-table-wrapper"
        :class="{'sm': isModal}"
    >
        <table class="subscription-table" :class="{'sm': isModal}">
            <thead>
            <tr>
                <th class="number"></th>
                <th class="date">Дата начала</th>
                <th class="date">Дата окончания</th>
                <th>Счет на оплату</th>
                <th>Договор</th>
                <th>Акт выполненных работ</th>
                <th>Статус</th>
                <th v-if="isModal" class="actions"></th>
            </tr>
            </thead>
            <tbody>
            <SubscriptionHistoryTableRow
                v-for="(subscription, index) in subscriptions"
                :key="'subscription_' + subscription.id"
                :subscription="subscription"
                :index="index"
                :isModal="isModal"
                :disabled-dates="disabledDates"
            />
            </tbody>
        </table>
    </div>
</template>
<script>
    import SubscriptionHistoryTableRow from "@/components/profilePage/SubscriptionHistoryTableRow"
    import {mapGetters} from "vuex"

    export default {
        name: "SubscriptionHistoryTable",
        props: ['isModal', 'subscriptions', 'disabledDates'],
        components: {
            SubscriptionHistoryTableRow
        },
        computed: {
            ...mapGetters('profile', ['profileId']),
        },
    }
</script>
<style lang="less" scoped>
    @import '../../assets/less/variables.less';
    .subscription-table-wrapper {
        overflow: auto;
        //padding-top - tabs - subscription - title - pagination
        height: calc(100vh - 16px - 64px - 120px - 40px - 48px);
        &.sm {
            height: auto;
            //modal-header - modal margin top - modal margin bottom - div padding-top - div padding-bottom - button margin - button height
            max-height: calc(100vh - 63px - 64px - 64px - 24px - 24px - 16px - 48px);
            @media (max-height: 600px) {
                //modal-header - modal margin top - modal margin bottom - div padding-top - div padding-bottom - button margin - button height
                max-height: calc(100vh - 63px - 32px - 32px - 24px - 24px - 16px - 48px);
            }
        }
        &::-webkit-scrollbar {
            border-radius: @br;
            width: 8px;
            height: 8px;
            background-color: #ffffff;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: @br;
            width: 8px;
            height: 8px;
            background-color: fade(#000000, 24%);
            &:hover {
                background-color: fade(#000000, 30%);
            }
        }
    }
    .subscription-table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        height: auto;
        text-align: left;
        th {
            position: sticky;
            top: 0;
            z-index: 1;
            vertical-align: bottom;
            border: none;
            border-bottom: 2px solid @bg-color-light;
            padding: 12px 16px;
            font-weight: 500;
            color: @text-color-light;
            background-color: #ffffff;
            &.number {
                padding: 12px 0 12px 16px;
                min-width: 60px;
            }
            &.date {
                min-width: 125px;
            }
            &.actions {
                padding: 12px 16px 12px 0;
                width: 40px;
            }
        }
        &.sm {
            th {
                padding: 24px 8px 16px;
                &.number {
                    padding: 8px 4px;
                    min-width: 0;
                    width: 42px;
                }
                &.actions {
                    padding: 8px 4px;
                }
                &.status {
                    min-width: 190px;
                }
            }
        }
    }
</style>