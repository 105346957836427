<template>
    <div class="modal-content">
        <button
                class="btn btn-success"
                type="button"
                @click="addSubscription"
        >
            Добавить новую подписку
        </button>
        <SubscriptionHistoryTable
                :subscriptions="mapSubscriptions"
                :disabled-dates="disabledDates"
                :is-modal="true"
        />
        <div v-if="isConfirmationVisible" class="confirmation">
            <div class="confirmation-in">
                <div class="ico-wrapper">
                    <ico name="icoHelp" color="#9fabb1fa" size="72"></ico>
                </div>
                <p>Вы действительно хотите удалить подписку от <strong>{{confirmedSubscriptionStartDate}}</strong>?</p>
                <div class="form-footer">
                    <button
                            class="btn btn-danger"
                            type="button"
                            @click="removeSubscription"
                    >
                        Удалить
                    </button>
                    <button
                            class="btn btn-transparent"
                            type="button"
                            @click="closeModal"
                    >
                        Отмена
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapActions, mapGetters} from 'vuex'
    import SubscriptionHistoryTable from "@/components/profilePage/SubscriptionHistoryTable"
    import addDays from 'date-fns/addDays'
    import {eventBus} from "@/main"
    import {uuid} from 'vue-uuid'
    import {concat, filter, find, isEmpty, isEqual, map, values} from "lodash"
    import api from "@/application"
    import format from "date-fns/format"
    import {subscriptionSize} from "@/utils/constants/subscription"

    export default {
        name: "SubscriptionHistoryModal",
        data() {
            return {
                isConfirmationVisible: false,
                confirmedSubscriptionStartDate: null,
                confirmedSubscriptionId: null,
                updatedSubscriptions: {},
                newSubscriptions: {},
                isOrganizationExists: false,
            }
        },
        props: ['componentProps'],
        components: {
            SubscriptionHistoryTable
        },
        computed: {
            ...mapGetters('subscriptions', ['generalSubscriptions', 'subscriptions']),
            ...mapGetters('enums', ['subscriptionStatusesTypes']),
            mapSubscriptions() {
                const dbSubscriptions = map(this.generalSubscriptions, it => ({...it, isSubscriptionSaved: true}))

                return concat(
                    values(this.newSubscriptions),
                    map(dbSubscriptions, it => this.updatedSubscriptions[it.id] ?? it),
                )
            },
            disabledDates() {

                return map(filter(this.subscriptions, subscription => subscription.status.id === 2 || subscription.status.id === 3), ({startDate, endDate}) => ({start: startDate, end: endDate}))
            }
        },
        methods: {
            ...mapActions('subscriptions', ['getSubscriptions', 'deleteSubscription', 'editSubscription', 'createSubscription', 'resetSubscriptions']),
            ...mapActions('enums', ['getSubscriptionStatusesTypes']),
            async addSubscription() {

                if (!this.isOrganizationExists) {

                    const {data} = await api.organization.isExistsOrganizationByProfileId(this.componentProps.profileId)
                    this.isOrganizationExists = data
                    if (!data) {
                        this.$toast.error('У данного пользователя отсутствует информация об организации. Невозможно создать счет на оплату без данной информации!')
                        return
                    }
                }

                const now = new Date()
                const id = uuid.v4()
                this.newSubscriptions = {
                    ...this.newSubscriptions,
                    [id]: {
                        id,
                        startDate: now,
                        endDate: addDays(now, subscriptionSize),
                        status: {
                            id: 1,
                            value: 'Ожидает оплаты'
                        },
                        isSubscriptionSaved: false,
                    }
                }
            },
            changeNewSubscription(id, editedSubscription) {
                this.newSubscriptions = {
                    ...this.newSubscriptions,
                    [id]: {
                        ...editedSubscription,
                    }
                }
            },
            // eslint-disable-next-line no-unused-vars
            changeSubscription(id, {isSubscriptionSaved, ...editedSubscription}) {
                const saved = isEqual(find(this.generalSubscriptions, ['id', id]), editedSubscription)
                if (!saved) {
                    this.updatedSubscriptions = {
                        ...this.updatedSubscriptions,
                        [id]: {
                            ...editedSubscription,
                            isSubscriptionSaved: false,
                        }
                    }
                    return
                }

                // eslint-disable-next-line no-unused-vars
                const {[id]: removed, ...rest} = this.updatedSubscriptions
                this.updatedSubscriptions = rest
            },
            showModal(id, startDate) {
                this.confirmedSubscriptionStartDate = startDate
                this.confirmedSubscriptionId = id
                this.isConfirmationVisible = true
            },
            closeModal() {
                this.isConfirmationVisible = false
            },
            removeNewSubscription(id) {
                // eslint-disable-next-line no-unused-vars
                const {[id]: removed, ...rest} = this.newSubscriptions
                this.newSubscriptions = rest
            },
            removeUpdatedSubscription(id) {
                // eslint-disable-next-line no-unused-vars
                const {[id]: removed, ...rest} = this.updatedSubscriptions
                this.updatedSubscriptions = rest
            },
            async saveSubscription(newSubscriptionData) {
                const {id, startDate, endDate, ...rest} = newSubscriptionData
                try {
                    await this.createSubscription({
                        startDate: format(startDate, 'yyyy-MM-dd'),
                        endDate: format(endDate, 'yyyy-MM-dd'),
                        subscriptionStatusDropdown: `/${rest.status.id}`,
                        profile: `/${this.componentProps.profileId}`
                    })
                    this.removeNewSubscription(id)
                    this.getSubscriptions(this.componentProps.profileId)
                } catch (e) {
                    this.$toast.error(e.message)
                }

            },
            async removeSubscription() {
                try {
                    await this.deleteSubscription(this.confirmedSubscriptionId)
                    this.removeUpdatedSubscription(this.confirmedSubscriptionId)
                    this.getSubscriptions(this.componentProps.profileId)
                    this.closeModal()
                } catch (e) {
                    this.$toast.error(e.message)
                }
            },
            async updateSubscription(subscriptionId, editedSubscription) {

                const subscriptionData = {
                    startDate: format(editedSubscription.startDate, 'yyyy-MM-dd'),
                    endDate: format(editedSubscription.endDate, 'yyyy-MM-dd'),
                    subscriptionStatusDropdown: `/${editedSubscription.status.id}`,
                }
                try {
                    await this.editSubscription({subscriptionId, subscriptionData})
                    this.removeUpdatedSubscription(subscriptionId)
                    this.getSubscriptions(this.componentProps.profileId)
                } catch (e) {
                    this.$toast.error(e.message)
                }
            }

        },
        async created() {
            isEmpty(this.subscriptionStatusesTypes) && await this.getSubscriptionStatusesTypes()
            await this.getSubscriptions(this.componentProps.profileId)
            eventBus.$on('showConfirmation', this.showModal)
            eventBus.$on('changeSubscription', this.changeSubscription)
            eventBus.$on('changeNewSubscription', this.changeNewSubscription)
            eventBus.$on('removeNewSubscription', this.removeNewSubscription)
            eventBus.$on('saveSubscription', this.saveSubscription)
            eventBus.$on('updateSubscription', this.updateSubscription)
        },
        beforeDestroy() {
            this.resetSubscriptions()
            eventBus.$off('showConfirmation')
            eventBus.$off('changeSubscription')
            eventBus.$off('changeNewSubscription')
            eventBus.$off('removeNewSubscription')
            eventBus.$off('saveSubscription')
            eventBus.$off('updateSubscription')
        }
    }
</script>
<style lang="less" scoped>
    @import '../../assets/less/variables.less';

    .modal-content {
        position: relative;
    }

    .confirmation {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: fade(#ffffff, 75%);

        &-in {
            border-radius: 4px;
            padding: 24px;
            max-width: 352px;
            background-color: @bg-color;

            .ico-wrapper {
                margin: 0 auto 16px;
                width: 72px;
            }

            p {
                text-align: center;
                color: #ffffff;
            }

            .form-footer {
                padding-top: 24px;
            }
        }
    }
</style>